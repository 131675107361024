import styled, { css } from "styled-components";
import {
  fadeIn,
  slideUp,
  slideInFromLeft,
  slideInFromRight,
} from "../Animations/animations";
import { Link } from "react-router-dom";

export const HeroWrapper = styled.section`
  position: relative;
  background-image: url(https://lunexdirect.solar/hero.webp);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 37.5em) {
    min-height: 80vh;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 10;
  }
`;

export const HeroLogo = styled.img`
  max-width: 52rem;
  aspect-ratio: 3.6497;
  padding: 1.2rem 1.2rem 0 1.2rem;
  z-index: 11;
  animation: ${fadeIn} 1.5s ease forwards;

  @media (max-width: 28.125em) {
    //450px
    max-width: 90vw;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-top: 10rem;
  padding: 1.2rem 0 4.8rem 0;
  z-index: 12;
  width: 100%;

  @media (max-width: 59.5em) {
    //952px
    margin-top: 12rem;
  }
`;

export const TitleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 4rem auto;
  padding: 0 2rem;
  flex-wrap: wrap;
`;

export const SectionTitle = styled.h1`
  font-size: 5.4rem;
  font-weight: 400;
  color: #ffa500;
  color: white;
  text-align: center;
  animation: ${fadeIn} 1.5s ease forwards;

  @media (max-width: 28.125em) {
    //450px
    font-size: 4.8rem;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  height: auto;
  padding: 0 4rem;

  @media (max-width: 62.5em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  @media (max-width: 46.875em) {
    //750px
    display: flex;
    flex-direction: column;
  }
`;

export const CardTransition = styled.div`
  transition: all 0.3s ease;

  @media (max-width: 62.5em) {
    grid-column: ${({ $cardNum }) =>
      $cardNum === 1
        ? "1"
        : $cardNum === 2
        ? "2"
        : $cardNum === 3
        ? "1 / -1"
        : "auto"};
  }

  @media (max-width: 46.875em) {
    //750px
    grid-column: unset;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* height: 46rem; */
  width: 27.5vw;
  margin-bottom: 6rem;
  background-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: ${({ $cardNum }) =>
    $cardNum === 1
      ? "translateX(-100px) scale(1)"
      : $cardNum === 2
      ? "translateY(100px) scale(1)"
      : $cardNum === 3
      ? "translateX(100px) scale(1)"
      : "none"};

  ${({ $visible, $cardNum }) =>
    $visible &&
    css`
      animation: ${$cardNum === 1
          ? slideInFromLeft
          : $cardNum === 2
          ? slideUp
          : $cardNum === 3
          ? slideInFromRight
          : null}
        1.5s ease forwards;
    `};

  @media (max-width: 62.5em) {
    //1000px
    min-width: 40vw;
  }

  @media (max-width: 46.875em) {
    //750px
    min-width: 60vw;

    ${({ $visible, $cardNum }) =>
      $visible &&
      css`
        animation: ${$cardNum === 1
            ? slideInFromLeft
            : $cardNum === 2
            ? slideInFromRight
            : $cardNum === 3
            ? slideUp
            : null}
          1.5s ease forwards;
      `};
  }
  @media (max-width: 46.875em) {
    //750px
    ${({ $visible, $cardNum }) =>
      $visible &&
      css`
        animation: ${$cardNum === 1
            ? slideInFromLeft
            : $cardNum === 2
            ? slideInFromRight
            : $cardNum === 3
            ? slideInFromLeft
            : null}
          1s ease forwards;
      `}
  }

  &:hover {
    box-shadow: 1px 1px 16px rgba(255, 255, 255, 0.99);
    background-color: #ffedcc;
    background-color: #e6e6e6;
    background-color: #101522;
  }
`;

export const CardImage = styled.img`
  width: 27.5vw;
  aspect-ratio: 2.1316;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 2rem;

  @media (max-width: 62.5em) {
    //1000px
    min-width: 40vw;
  }

  @media (max-width: 46.875em) {
    //750px
    min-width: 60vw;
  }
`;

export const CardTitleTextWrapper = styled.div`
  min-height: 28rem;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h2`
  font-size: 3.2rem;
  font-weight: 500;
  color: #101522;
  color: white;
  text-align: center;
  margin: 0 2rem 2rem 2rem;
  text-transform: uppercase;
`;

export const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 2rem auto 2rem;
  padding-bottom: 4rem;
  gap: 2rem;
`;

export const DashTextWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: flex-start;
`;

export const Dash = styled.p`
  font-size: 1.8rem;
  color: #101522;
  color: white;
  align-self: flex-start;
  text-align: left;
`;

export const CardText = styled.p`
  font-size: 1.8rem;
  color: #101522;
  color: white;
  width: 100%;
  align-self: flex-start;
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const AppButtonLink = styled.a`
  &:link,
  &:visited {
    background-color: #1ba8f1;
    color: white;
    font-size: 2rem;
    text-decoration: none;
    display: inline-block;
    padding: 2.2rem 4.4rem;
    border-radius: 10px;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    opacity: 0;

    transform: translateX(100px);
    ${({ $visible }) =>
      $visible &&
      css`
        animation: ${slideInFromRight} 1s ease forwards;
      `}

    @media (max-width: 40.625em) {
      padding: 1.6rem 3.2rem;
    }

    @media (max-width: 31.25em) {
      font-size: 1.6rem;
    }
  }

  &:hover,
  &:active {
    background-color: #1897d9;
    /* border: 1px solid #ffa500;
    color: #ffa500; */
  }
`;

export const GuideButtonLink = styled(Link)`
  &:link,
  &:visited {
    background-color: white;
    background-color: none;
    /* border: 1px solid white; */
    color: #101522;
    /* color: white; */
    font-size: 2rem;
    text-decoration: none;
    display: inline-block;
    padding: 2.2rem 4.4rem;
    border-radius: 10px;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    opacity: 0;
    transform: translateX(-100px);
    ${({ $visible }) =>
      $visible &&
      css`
        animation: ${slideInFromLeft} 1s ease forwards;
      `}

    @media (max-width: 40.625em) {
      padding: 1.6rem 3.2rem;
    }

    @media (max-width: 31.25em) {
      font-size: 1.6rem;
    }
  }

  &:hover,
  &:active {
    /* border: 1px solid #1ba8f1; */

    /* color: #1ba8f1; */
    background-color: #e6e6e6;
  }
`;
