import { useEffect, useRef, useState } from "react";
import {
  MobileNav,
  MobileNavWrapper,
  MobileNavLogo,
  HamburgerButton,
  HamburgerWrapper,
  OpenNavDropDown,
  DropDownLink,
  SocialLinkWrapper,
  SocialLink,
  LunexPowerImg,
  LogoHamburgerWrapper,
  MobileNavLogoLinkWrapper,
  MobileNavButtonLink,
} from "./MobileNavElements";
import {
  FaBars,
  FaX,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa6";

const MobileNavBar = function () {
  const [navOpen, setNavOpen] = useState(false);
  const navRef = useRef(null);

  const handleToggle = function () {
    setNavOpen(!navOpen);
  };

  const handleClickOutside = function (event) {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavOpen(false);
    }
  };

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navOpen]);

  return (
    <MobileNavWrapper ref={navRef}>
      <MobileNav $navOpen={navOpen}>
        <LogoHamburgerWrapper>
          <MobileNavLogoLinkWrapper to="/">
            <MobileNavLogo
              src="lunex-DIRECT-color-transparent-web.svg"
              alt="Lunex Logo"
            />
          </MobileNavLogoLinkWrapper>
          <HamburgerWrapper>
            <HamburgerButton
              onClick={handleToggle}
              aria-label="Hamburger button to open mobile navigation"
            >
              {navOpen ? <FaX /> : <FaBars />}
            </HamburgerButton>
          </HamburgerWrapper>
        </LogoHamburgerWrapper>
        {navOpen ? (
          <OpenNavDropDown>
            <MobileNavButtonLink href={import.meta.env.VITE_APP_URL}>
              GO TO APP
            </MobileNavButtonLink>
            <DropDownLink to="/">Home</DropDownLink>
            <DropDownLink to="/faq">FAQ</DropDownLink>
            <DropDownLink to="/incentives">Incentives</DropDownLink>
            <DropDownLink to="/guide">Guide</DropDownLink>
            <SocialLinkWrapper>
              <SocialLink href="https://www.facebook.com/Lunexpowerinc/">
                <FaFacebookF />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/lunexpowerinc/?hl=en">
                <FaInstagram />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/lunex-power-inc/">
                <FaLinkedinIn />
              </SocialLink>
            </SocialLinkWrapper>
            <a href="https://lunexpower.com/">
              <LunexPowerImg
                src="lunex-power-color-transparent-slogan.webp"
                alt="Lunex Power logo"
              />
            </a>
          </OpenNavDropDown>
        ) : (
          ""
        )}
      </MobileNav>
    </MobileNavWrapper>
  );
};

export default MobileNavBar;
