import { styled, css } from "styled-components";
import { Link } from "react-router-dom";

export const NBarWrapper = styled.div`
  position: relative;
`;

export const NBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10rem;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.05)
  );
  ${(props) =>
    props.hidden &&
    css`
      transform: translateY(-100%);
    `};
`;

export const NBarLeftWrapper = styled.div`
  width: 20rem;
  width: 20vw;
  display: flex;
  align-items: center;
`;

export const NavLogo = styled.img`
  width: 16rem;
  height: 8rem;
  cursor: pointer;
  z-index: 12;
`;

export const NBarMiddleWrapper = styled.div`
  width: 20rem;
  width: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const NavPageLink = styled(Link)`
  &:link,
  &:visited {
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #ffa500;
  }
`;

export const NavLink = styled.a`
  &:link,
  &:visited {
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #ffa500;
  }
`;

export const NBarRightWrapper = styled.div`
  width: 20rem;
  width: 20vw;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 2rem;
`;

export const NavButtonLink = styled.a`
  &:link,
  &:visited {
    background-color: #1ba8f1;
    color: #fff;
    font-size: 1.4rem;
    text-decoration: none;
    display: inline-block;
    padding: 1.2rem 1.6rem;
    border-radius: 10px;
    z-index: 11;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:active {
    background-color: #1897d9;
  }
`;
