import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Home from "../pages/home";
const FAQPage = lazy(() => import("../pages/faq"));
const IncentivesPage = lazy(() => import("../pages/incentives"));
const GuidePage = lazy(() => import("../pages/guide"));
const NotFound = lazy(() => import("../pages/notFound"));

function App() {
  return (
    <>
      <Router>
        {/* ScrollToTop will make sure the page scrolls to top on each route change */}
        <ScrollToTop />
        {/* Wrap routes in Suspense to provide a fallback */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/incentives" element={<IncentivesPage />} />
            <Route path="/guide" element={<GuidePage />} />
            <Route path="*" status={404} element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
