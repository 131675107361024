import { useState, useRef, useEffect } from "react";
import {
  HeroWrapper,
  HeroLogo,
  CardWrapper,
  Card,
  CardImage,
  CardTitle,
  CardText,
  SectionWrapper,
  SectionTitle,
  CardTransition,
  CardTextWrapper,
  ButtonWrapper,
  AppButtonLink,
  GuideButtonLink,
  DashTextWrapper,
  Dash,
  TitleLogoWrapper,
  CardTitleTextWrapper,
} from "./HeroElements";

import ProposalImage from "../../../public/ProposalImage-Cropped.webp";

const NewHeroSection = function () {
  const [isVisible, setIsVisible] = useState({
    card1: false,
    card2: false,
    card3: false,
    appButton: false,
    guideButton: false,
  });

  const cardRefs = useRef([useRef(null), useRef(null), useRef(null)]);
  const appButtonRef = useRef(null);
  const guideButtonRef = useRef(null);

  const observeElement = (ref, key) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible((prev) => ({ ...prev, [key]: true }));
          observer.disconnect();
        }
      },
      { threshold: 0.25 }
    );
    if (ref.current) observer.observe(ref.current);
    return observer;
  };

  useEffect(() => {
    const cardObservers = cardRefs.current.map((ref, index) =>
      observeElement(ref, `card${index + 1}`)
    );
    const appButtonObserver = observeElement(appButtonRef, "appButton");
    const guideButtonObserver = observeElement(guideButtonRef, "guideButton");

    return () => {
      cardObservers.forEach((observer) => observer.disconnect());
      appButtonObserver.disconnect();
      guideButtonObserver.disconnect();
    };
  }, []);

  return (
    <HeroWrapper>
      <SectionWrapper>
        <TitleLogoWrapper>
          <SectionTitle>Check out the NEW</SectionTitle>
          <HeroLogo
            src="lunex-DIRECT-color-transparent-web.svg"
            alt="Lunex logo"
          />
          <SectionTitle>App!</SectionTitle>
        </TitleLogoWrapper>

        <CardWrapper>
          <CardTransition $cardNum={1}>
            <Card
              ref={cardRefs.current[0]}
              $visible={isVisible.card1}
              $cardNum={1}
            >
              <CardImage
                src="pushySalesman.webp"
                alt="Pushy door to door salesman"
              />
              <CardTitleTextWrapper>
                <CardTitle>No Salesman</CardTitle>
                <CardTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Pocket the savings</CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>No pressure</CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Simple, straightforward process</CardText>
                  </DashTextWrapper>
                </CardTextWrapper>
              </CardTitleTextWrapper>
            </Card>
          </CardTransition>
          <CardTransition $cardNum={2}>
            <Card
              ref={cardRefs.current[1]}
              $visible={isVisible.card2}
              $cardNum={2}
            >
              <CardImage
                src="SolarHouseAerialView-Cropped.webp"
                alt="filler-image"
              />
              <CardTitleTextWrapper>
                <CardTitle>AI Generated Design</CardTitle>
                <CardTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Custom design</CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>
                      AI tools determine optimal panel placement
                    </CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Design ready within minutes</CardText>
                  </DashTextWrapper>
                </CardTextWrapper>
              </CardTitleTextWrapper>
            </Card>
          </CardTransition>
          <CardTransition $cardNum={3}>
            <Card
              ref={cardRefs.current[2]}
              $visible={isVisible.card3}
              $cardNum={3}
            >
              <CardImage src={ProposalImage} alt="filler-image" />
              <CardTitleTextWrapper>
                <CardTitle>Instant Pricing</CardTitle>
                <CardTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Receive formal pricing through app</CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>Everyday low pricing</CardText>
                  </DashTextWrapper>
                  <DashTextWrapper>
                    <Dash>-</Dash>
                    <CardText>No hassle, no haggle</CardText>
                  </DashTextWrapper>
                </CardTextWrapper>
              </CardTitleTextWrapper>
            </Card>
          </CardTransition>
        </CardWrapper>
        <ButtonWrapper>
          <GuideButtonLink
            to="/guide"
            ref={guideButtonRef}
            $visible={isVisible.guideButton}
          >
            How To Use App
          </GuideButtonLink>
          <AppButtonLink
            href={import.meta.env.VITE_APP_URL}
            ref={appButtonRef}
            $visible={isVisible.appButton}
          >
            Go To App
          </AppButtonLink>
        </ButtonWrapper>
      </SectionWrapper>
    </HeroWrapper>
  );
};

export default NewHeroSection;
