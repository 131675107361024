import NavBar from "../NavBar";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import MobileNavBar from "../MobileNavBar";
// import MobileNavBar from "../MobileNavBar";

const Layout = function ({ children }) {
  // State to track the screen width
  const [isMobile, setIsMobile] = useState(false);

  // Effect hook to listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      // Check if screen width is less than 1000px to determent mobile layout
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct layout on page load
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <MobileNavBar /> : <NavBar />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
