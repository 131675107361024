import { css } from "styled-components";
import styled from "styled-components";
import { slideUp } from "../Animations/animations";

export const WhyLunexWrapper = styled.section`
  width: 100%;
  background: linear-gradient(to bottom, #cfd0d3, #e7e8e9);
  padding-bottom: 7rem;
`;

export const WhyLunexTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7rem 1rem 0rem 1rem;
`;

export const WhyLunexBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6rem 4rem 0 4rem;
  gap: 4rem;

  flex-wrap: wrap;
`;

export const WhyLunexHeader = styled.h2`
  font-size: 5.4rem;
  letter-spacing: -2px;
  color: #6b7278;
  color: #101522;
  font-weight: 400;
  text-align: center;
`;

export const WhyLunexSubHeader = styled.p`
  font-size: 1.6rem;
  color: #6b7278;
  color: #101522;
  font-weight: 400;
`;

export const LunexFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  transform: translateY(100px);
  opacity: 0;

  ${({ $visible, $featureNum }) =>
    $visible && $featureNum === 1
      ? css`
          animation: ${slideUp} 0.5s ease forwards;
        `
      : $visible && $featureNum === 2
      ? css`
          animation: ${slideUp} 1s ease forwards;
        `
      : $visible && $featureNum === 3
      ? css`
          animation: ${slideUp} 1.5s ease forwards;
        `
      : $visible && $featureNum === 4
      ? css`
          animation: ${slideUp} 2s ease forwards;
        `
      : $visible && $featureNum === 5
      ? css`
          animation: ${slideUp} 2.5s ease forwards;
        `
      : ""};
`;

export const LunexFeatureIcon = styled.div`
  color: #6b7278;
  color: #101522;
  size: 100%;
  height: 6rem;
  width: 6rem;
  align-self: center;
  justify-self: center;

  &:hover {
    color: #ffa500;
    color: #007aff;
  }
`;

export const LunexFeatureText = styled.p`
  color: #6b7278;
  color: #101522;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  align-self: center;
  justify-self: center;
`;
