import styled from "styled-components";
import { Link } from "react-router-dom";

export const MobileNavWrapper = styled.div`
  position: relative;
`;
export const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  gap: 3rem;
  position: fixed;
  min-height: 10rem;
  transition: all 0.3s;
  height: ${(props) =>
    props.$navOpen ? "62rem" : "10rem"}; /* Transition height */
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  z-index: 51;
  overflow: hidden;
  background: ${(props) =>
    props.$navOpen
      ? `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, .95))`
      : `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))`};
`;

export const LogoHamburgerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileNavLogoLinkWrapper = styled(Link)`
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover,
  &:active {
    cursor: pointer;
  }
`;

export const MobileNavLogo = styled.img`
  width: 20rem;
  padding-left: 4rem;
`;

export const HamburgerWrapper = styled.div`
  display: flex;
  width: 20rem;
  padding-right: 4rem;
`;

export const HamburgerButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 3rem;
  transition: all 0.3s;

  &:hover {
    color: #ffa500;
    cursor: pointer;
  }
`;

export const OpenNavDropDown = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  gap: 4rem;
`;

export const MobileNavButtonLink = styled.a`
  &:link,
  &:visited {
    background-color: #1ba8f1;
    color: #fff;
    font-size: 1.8rem;
    text-decoration: none;
    display: inline-block;
    padding: 1.2rem 1.6rem;
    border-radius: 10px;
    z-index: 11;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
  }

  &:hover,
  &:active {
    background-color: #1897d9;
  }
`;

export const DropDownLink = styled(Link)`
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
  transition: all 0.3s;

  &:link,
  &:visited {
  }

  &:hover,
  &:active {
    cursor: pointer;
    color: #ffa500;
  }
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const SocialLink = styled.a`
  &:link,
  &:visited {
    font-size: 2rem;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #ffa500;
  }
`;

export const LunexPowerImg = styled.img`
  width: 15rem;
  margin: 0 auto;
  cursor: pointer;
`;
