import { useEffect, useState, useRef } from "react";
import { PiSunThin } from "react-icons/pi";
import { PiSeatbeltThin } from "react-icons/pi";
import { PiMoneyWavyThin } from "react-icons/pi";
import { PiBatteryChargingVerticalThin } from "react-icons/pi";
import { PiShieldCheckThin } from "react-icons/pi";
// import { PiUserMinusThin } from "react-icons/pi";
import {
  WhyLunexWrapper,
  WhyLunexTop,
  WhyLunexBottom,
  WhyLunexHeader,
  WhyLunexSubHeader,
  LunexFeatureIcon,
  LunexFeatureText,
  LunexFeatureWrapper,
} from "./WhyLunexElements";

const WhyLunex = function () {
  // to measure when user scrolls to this part of the page and trigger the initial effects
  const [isVisible, setIsVisible] = useState(false);
  const featureRef = useRef();

  useEffect(() => {
    const element = featureRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.01 }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <WhyLunexWrapper>
      <WhyLunexTop>
        <WhyLunexHeader>Why Lunex Direct?</WhyLunexHeader>
        <WhyLunexSubHeader>
          We are changing the face of solar.
        </WhyLunexSubHeader>
      </WhyLunexTop>
      <WhyLunexBottom>
        <LunexFeatureWrapper
          ref={featureRef}
          $visible={isVisible}
          $featureNum={1}
        >
          <LunexFeatureIcon>
            <PiSunThin size="100%" />
          </LunexFeatureIcon>
          <LunexFeatureText>renewable source</LunexFeatureText>
        </LunexFeatureWrapper>
        <LunexFeatureWrapper
          ref={featureRef}
          $visible={isVisible}
          $featureNum={2}
        >
          <LunexFeatureIcon>
            <PiSeatbeltThin size="100%" />
            {/* <PiUserMinusThin size="100%" /> */}
          </LunexFeatureIcon>
          <LunexFeatureText>no salesperson</LunexFeatureText>
        </LunexFeatureWrapper>
        <LunexFeatureWrapper
          ref={featureRef}
          $visible={isVisible}
          $featureNum={3}
        >
          <LunexFeatureIcon>
            <PiMoneyWavyThin size="100%" />
          </LunexFeatureIcon>
          <LunexFeatureText>reduced cost</LunexFeatureText>
        </LunexFeatureWrapper>
        <LunexFeatureWrapper
          ref={featureRef}
          $visible={isVisible}
          $featureNum={4}
        >
          <LunexFeatureIcon>
            <PiBatteryChargingVerticalThin size="100%" />
          </LunexFeatureIcon>
          <LunexFeatureText>battery backup</LunexFeatureText>
        </LunexFeatureWrapper>
        <LunexFeatureWrapper
          ref={featureRef}
          $visible={isVisible}
          $featureNum={5}
        >
          <LunexFeatureIcon>
            <PiShieldCheckThin size="100%" />
          </LunexFeatureIcon>
          <LunexFeatureText>triple warranty</LunexFeatureText>
        </LunexFeatureWrapper>
      </WhyLunexBottom>
    </WhyLunexWrapper>
  );
};

export default WhyLunex;
