export const InfoSectionObj1 = {
  borderTop: "12px solid #ffa500",
  borderBottom: "12px solid #ffa500",
  hasLogo: false,
  defaultColumnOrder: true,
  bgColorSection: "#101522",
  bgColorColumn1: "#101522",
  bgColorColumn2: "#101522",
  bgImage: "SolarHouseAerialView.webp",
  alt: "Solar House Aerial View",
  h3Text: "Our equipment is the most effective in the solar market.",
  pText:
    "American-made solar panels with industry leading 30-year warranties, along with 24/7 system monitoring to ensure you always know our system is producing.",
  h3FontSize: "4.8rem",
  pFontSize: "1.6rem",
  h3TextColor: "white",
  pTextColor: "white",
  hasButton: true,
};

export const InfoSectionObj2 = {
  borderTop: "none",
  borderBottom: "none",
  hasLogo: true,
  defaultColumnOrder: false,
  bgColorSection: "white",
  bgColorColumn1: "white",
  bgColorColumn2: "white",
  bgImage: "Powerwall3_77.webp",
  alt: "Powerwall3",
  h3Text: "Guarantees a clean installation.",
  pText:
    "Our production teams are all trained to provide the most seamless level of installation expertise in the business. We stand by our decades of collective experience.",
  h3FontSize: "4.8rem",
  pFontSize: "1.6rem",
  // h3TextColor: "#565b60",
  h3TextColor: "#101522",
  // pTextColor: "#565b60",
  pTextColor: "#101522",
  hasButton: true,
};

export const InfoSectionObj3 = {
  borderTop: "12px solid #ffa500",
  borderBottom: "12px solid #ffa500",
  hasLogo: false,
  defaultColumnOrder: true,
  bgColorSection: "#101522",
  bgColorColumn1: "#101522",
  bgColorColumn2: "#101522",
  bgImage: "Enphase.webp",
  alt: "Enphase screenshot",
  h3Text: "Monitor your system status from anywhere.",
  pText:
    "Our hardware comes with built-in panel monitoring software, so you always know exactly how much energy your solar system is producing. Generate reports on your energy production by day, week, month, or year. Visualize your solar data in attractive and easy-to-read graphs and infographics.",
  h3FontSize: "4.8rem",
  pFontSize: "1.6rem",
  h3TextColor: "white",
  pTextColor: "white",
  hasButton: true,
};
