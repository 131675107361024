import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.section`
  background-color: #101522;
  display: flex;
  flex-direction: column;
  padding: 3rem 10rem;

  @media (max-width: 48.8em) {
    padding: 3rem 6rem;
  }

  @media (max-width: 30.7em) {
    padding: 3rem 3rem;
  }
`;

export const FooterTop = styled.div`
  display: flex;
  gap: 30rem;
  margin: 0 auto 2rem auto;
  @media (max-width: 46.875em) {
    gap: 20rem;
  }

  @media (max-width: 30.7em) {
    gap: 8rem;
  }
`;

export const FooterTopColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
`;

export const ColumnTitle = styled.h6`
  font-size: 1.6rem;
  color: white;
  font-weight: 700;
`;

export const LinkGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-items: center;
`;

export const PageLink = styled(Link)`
  &:link,
  &:visited {
    font-size: 1.4rem;
    color: #ced4da;
    text-decoration: none;
    transition: all 0.2s;
  }

  &:hover,
  &:active {
    cursor: pointer;
    color: #ffa500;
  }
`;

export const FooterLink = styled.a`
  &:link,
  &:visited {
    font-size: 1.4rem;
    color: #ced4da;
    text-decoration: none;
    transition: all 0.2s;
  }

  &:hover,
  &:active {
    cursor: pointer;
    color: #ffa500;
  }
`;

export const LunexPowerImg = styled.img`
  width: 14rem;
  margin: 0 auto;
  cursor: pointer;
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LunexLogoWrapper = styled(Link)`
  &:link,
  &:visited {
    text-decoration: none;
    width: 20vw;
  }
  &:hover,
  &:active {
    cursor: pointer;
  }
`;

export const LunexLogo = styled.img`
  height: auto;
  width: 16vw;
  width: 16rem;
  /* @media (max-width: 39.0625em) {
    width: 18vw;
    width: 15rem;
  } */
`;

export const Copyright = styled.p`
  font-size: 1.2rem;
  color: #ced4da;
  padding-bottom: 1rem;
  flex-grow: 1;
  text-align: center;
  width: 20vw;

  @media (max-width: 39.0625em) {
    width: auto;
    margin-top: 1rem;
  }
`;

export const SocialLinkWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 20vw;
  justify-content: flex-end;
`;

export const SocialLink = styled.a`
  &:link,
  &:visited {
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    color: #ffa500;
  }
`;
