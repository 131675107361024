import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import {
  FooterWrapper,
  FooterTop,
  ColumnTitle,
  PageLink,
  FooterLink,
  FooterBottom,
  LunexLogoWrapper,
  LunexLogo,
  Copyright,
  SocialLinkWrapper,
  SocialLink,
  FooterTopColumn,
  LinkGrid,
  LunexPowerImg,
} from "./FooterElements";
import { useEffect, useState } from "react";
import lunexDirectLogo from "../../../public/lunex-DIRECT-color-transparent-web.svg";
import lunexPowerLogo from "../../../public/lunex-power-color-transparent-slogan.webp";

const Footer = function () {
  const year = new Date().getFullYear();

  // State to track the screen width
  const [smallFooter, setSmallFooter] = useState(false);

  // Effect hook to listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      // Check if screen width is less than 625px to determent mobile layout
      setSmallFooter(window.innerWidth <= 625);
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the correct layout on page load
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <FooterWrapper>
      <FooterTop>
        <FooterTopColumn>
          <ColumnTitle>Company</ColumnTitle>
          <LinkGrid>
            <PageLink to="/">Home</PageLink>
            <PageLink to="/faq">FAQ</PageLink>
            <PageLink to="/incentives">Incentives</PageLink>
            <PageLink to="/guide">Guide</PageLink>
          </LinkGrid>
          <FooterLink href="https://www.lunexpower.com">
            <LunexPowerImg
              src={lunexPowerLogo}
              alt="Lunex Power logo"
            ></LunexPowerImg>
          </FooterLink>
        </FooterTopColumn>
        <FooterTopColumn>
          <ColumnTitle>Contact us</ColumnTitle>
          <FooterLink href="https://maps.app.goo.gl/jZFrJeHP7Yxmjr7y7">
            4721 N Grady Ave
            <br />
            Tampa, FL 33614
          </FooterLink>
          <FooterLink href="tel:8135408807">(813) 540-8807</FooterLink>
          <FooterLink href="mailto:info@lunexpower.com">
            info@lunexpower.com
          </FooterLink>
        </FooterTopColumn>
      </FooterTop>
      <FooterBottom>
        <LunexLogoWrapper to="/">
          <LunexLogo src={lunexDirectLogo} alt="Lunex logo" />
        </LunexLogoWrapper>
        {smallFooter ? (
          ""
        ) : (
          <Copyright>Lunex Direct &#169; {year} all rights reserved</Copyright>
        )}
        <SocialLinkWrapper>
          <SocialLink
            href="https://www.facebook.com/Lunexpowerinc/"
            aria-label="Facebook link"
          >
            <FaFacebookF />
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/lunexpowerinc/?hl=en"
            aria-label="Instagram link"
          >
            <FaInstagram />
          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/company/lunex-power-inc/"
            aria-label="Linkedin link"
          >
            <FaLinkedinIn />
          </SocialLink>
        </SocialLinkWrapper>
      </FooterBottom>
      {smallFooter ? (
        <Copyright>Lunex Direct &#169; {year} all rights reserved</Copyright>
      ) : (
        ""
      )}
    </FooterWrapper>
  );
};

export default Footer;
