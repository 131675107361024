import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ScrollToTop component that scrolls to the top of the page on route change/ page refresh
const ScrollToTop = function () {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [location]); // Only run the effect when the location (route) changes

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"; // Checks to see if scroll restoration is enabled and switches it to manual if it is
    }
    window.scrollTo(0, 0); // Scrolls to the top of the page on refresh
  }, []); // Only runs once when component mounts

  return null;
};

export default ScrollToTop;
