import { lazy, Suspense } from "react";
import Layout from "../src/components/Layout";
import NewHeroSection from "../src/components/NewHeroSection";
import WhyLunex from "../src/components/WhyLunex";
const CustomerMap = lazy(() => import("../src/components/CustomerMap"));
const LunexDifference = lazy(() => import("../src/components/LunexDifference"));
const InfoSection = lazy(() => import("../src/components/InfoSection"));
const PartnerLogos = lazy(() => import("../src/components/PartnerLogos"));
// import LunexDifference from "../src/components/LunexDifference";
// import InfoSection from "../src/components/InfoSection";
// import PartnerLogos from "../src/components/PartnerLogos";
// import CustomerMap from "../src/components/CustomerMap";

import {
  InfoSectionObj1,
  InfoSectionObj2,
  InfoSectionObj3,
} from "../src/components/InfoSection/data";

function Home() {
  return (
    <>
      <Layout>
        {/* <HeroSection /> */}
        <NewHeroSection />
        <WhyLunex />
        {/* <LunexApp /> */}
        <Suspense fallback={<div>Loading...</div>}>
          <LunexDifference />
          <InfoSection {...InfoSectionObj1} />
          <PartnerLogos />
          <InfoSection {...InfoSectionObj2} />
          <InfoSection {...InfoSectionObj3} />
          <CustomerMap />
        </Suspense>
      </Layout>
    </>
  );
}

export default Home;
